<template>
  <a-modal
    :title="title"
    :width="modalWidth"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    cancelText="关闭"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="舊密碼">
          <a-input
            type="password"
            placeholder="請輸入舊密碼"
            v-decorator="[ 'oldpassword', validatorRules.oldpassword]"
          />
        </a-form-item>

        <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="新密碼">
          <a-input
            type="password"
            placeholder="請輸入新密碼"
            v-decorator="[ 'password', validatorRules.password]"
          />
        </a-form-item>

        <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="確認新密碼">
          <a-input
            type="password"
            @blur="handleConfirmBlur"
            placeholder="請確認新密碼"
            v-decorator="[ 'confirmpassword', validatorRules.confirmpassword]"
          />
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { putAction } from '@/api/manage';
import { mapActions } from 'vuex';

export default {
    name: 'UserPassword',
    data() {
        return {
            title: '修改密码',
            modalWidth: 600,
            visible: false,
            confirmLoading: false,
            validatorRules: {
                oldpassword: {
                    rules: [
                        {
                            required: true,
                            message: '請輸入舊密碼!'
                        }
                    ]
                },
                password: {
                    rules: [
                        {
                            required: true,
                            message: '請輸入新密碼!'
                        },
                        {
                            validator: this.validateToNextPassword
                        }
                    ]
                },
                confirmpassword: {
                    rules: [
                        {
                            required: true,
                            message: '請確認新密碼!'
                        },
                        {
                            validator: this.compareToFirstPassword
                        }
                    ]
                }
            },
            confirmDirty: false,
            labelCol: {
                xs: { span: 24 },
                sm: { span: 5 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 }
            },

            form: this.$form.createForm(this),
            url: '/user/updatePwd',
            userId: ''
        };
    },
    methods: {
        show(userId) {
            if (!userId) {
                this.$message.warning('當前系統無登陸用戶!');
            } else {
                this.userId = userId;
                this.form.resetFields();
                this.visible = true;
            }
        },
        handleCancel() {
            this.close();
        },
        close() {
            this.$emit('close');
            this.visible = false;
            this.disableSubmit = false;
            this.selectedRole = [];
        },
        ...mapActions(['Logout']),
        handleOk() {
            const that = this;
            // 触发表单验证
            this.form.validateFields((err, values) => {
                if (!err) {
                    that.confirmLoading = true;
                    const params = { userId: this.userId, ...values };
                    console.log('修改密码提交数据', params);
                    putAction(this.url, params)
                        .then(res => {
                            if (res.code === 200) {
                                if (res.data.status === 2 || res.data.status === 3) {
                                    that.$message.warning(res.data.message);
                                } else {
                                    that.$message.success(res.data.message);
                                    that.Logout({})
                                        .then(() => {
                                            window.location.href = '/';
                                            // window.location.reload()
                                        })
                                        .catch(err => {
                                            that.$message.error({
                                                title: '錯誤',
                                                description: err.message
                                            });
                                        });
                                }
                                that.close();
                            } else {
                                that.$message.warning(res.data.message);
                            }
                        })
                        .finally(() => {
                            that.confirmLoading = false;
                        });
                }
            });
        },
        validateToNextPassword(rule, value, callback) {
            const { form } = this;
            if (value && this.confirmDirty) {
                form.validateFields(['confirm'], { force: true });
            }
            callback();
        },
        compareToFirstPassword(rule, value, callback) {
            const { form } = this;
            if (value && value !== form.getFieldValue('password')) {
                callback('兩次輸入的密碼不壹樣！');
            } else {
                callback();
            }
        },
        handleConfirmBlur(e) {
            const { value } = e.target;
            this.confirmDirty = this.confirmDirty || !!value;
        }
    }
};
</script>

<style scoped>
</style>


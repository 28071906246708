import {
  getAction,
  deleteAction,
  putAction,
  postAction,
  httpAction,
} from "@/api/manage";
import Vue from "vue";
import { UI_CACHE_DB_DICT_DATA } from "@/store/mutation-types";

//首頁统计
const getBuyAndSaleStatistics = (params) =>
  getAction("/depotHead/getBuyAndSaleStatistics", params);
const buyOrSalePrice = (params) =>
  getAction("/depotItem/buyOrSalePrice", params);

//租户管理
const checkTenant = (params) => getAction("/tenant/checkIsNameExist", params);
const editTenant = (params) => putAction("/tenant/update", params);
const getTenantSelect = (params) => getAction("/tenant/select-list", params);

//角色管理
const addRole = (params) => postAction("/role/add", params);
const editRole = (params) => putAction("/role/update", params);
const checkRole = (params) => getAction("/role/checkIsNameExist", params);
const findUserRole = (params) => getAction("/role/findUserRole", params);
const queryall = (params) => getAction("/sys/role/queryall", params);

//用户管理
const registerUser = (params) => postAction("/user/registerUser", params);
const addUser = (params) => postAction("/user/addUser", params);
const editUser = (params) => putAction("/user/updateUser", params);
const getUserList = (params) => getAction("/user/getUserList", params);
//验证用户是否存在
const checkOnlyUser = (params) => getAction("/sys/user/checkOnlyUser", params);
//改变密码
const changePassword = (params) =>
  putAction("/sys/user/changePassword", params);

//权限管理
const addPermission = (params) => postAction("/sys/permission/add", params);
const editPermission = (params) => putAction("/sys/permission/edit", params);
const getPermissionList = (params) => getAction("/sys/permission/list", params);
const getSystemMenuList = (params) =>
  getAction("/sys/permission/getSystemMenuList", params);
const getSystemSubmenu = (params) =>
  getAction("/sys/permission/getSystemSubmenu", params);
const getSystemSubmenuBatch = (params) =>
  getAction("/sys/permission/getSystemSubmenuBatch", params);

const queryTreeList = (params) =>
  getAction("/sys/permission/queryTreeList", params);
const queryTreeListForRole = (params) =>
  getAction("/sys/role/queryTreeList", params);
const queryListAsync = (params) =>
  getAction("/sys/permission/queryListAsync", params);
const queryRolePermission = (params) =>
  getAction("/sys/permission/queryRolePermission", params);
const saveRolePermission = (params) =>
  postAction("/sys/permission/saveRolePermission", params);
const queryPermissionsByUser = (params) =>
  postAction("/function/findMenuByPNumber", params);
const loadAllRoleIds = (params) =>
  getAction("/sys/permission/loadAllRoleIds", params);
const getPermissionRuleList = (params) =>
  getAction("/sys/permission/getPermRuleListByPermId", params);
const queryPermissionRule = (params) =>
  getAction("/sys/permission/queryPermissionRule", params);

//机构管理
const queryOrganizationTreeList = (params) =>
  getAction("/organization/getOrganizationTree", params);
const queryOrganizationById = (params) =>
  getAction("/organization/findById", params);
const checkOrganization = (params) =>
  getAction("/organization/checkIsNameExist", params);
const queryParentName = (params) =>
  getAction("/sys/sysDepart/queryParentName", params);
const searchByKeywords = (params) =>
  getAction("/sys/sysDepart/searchBy", params);
const deleteByDepartId = (params) =>
  deleteAction("/sys/sysDepart/delete", params);

//二级部门管理
const queryDepartPermission = (params) =>
  getAction("/sys/permission/queryDepartPermission", params);
const saveDepartPermission = (params) =>
  postAction("/sys/permission/saveDepartPermission", params);
const queryTreeListForDeptRole = (params) =>
  getAction("/sys/sysDepartPermission/queryTreeListForDeptRole", params);
const queryDeptRolePermission = (params) =>
  getAction("/sys/sysDepartPermission/queryDeptRolePermission", params);
const saveDeptRolePermission = (params) =>
  postAction("/sys/sysDepartPermission/saveDeptRolePermission", params);
const queryMyDepartTreeList = (params) =>
  getAction("/sys/sysDepart/queryMyDeptTreeList", params);

//日志管理
const deleteLog = (params) => deleteAction("/sys/log/delete", params);
const deleteLogList = (params) => deleteAction("/sys/log/deleteBatch", params);

//数据字典
const addDict = (params) => postAction("/sys/dict/add", params);
const editDict = (params) => putAction("/sys/dict/edit", params);
const treeList = (params) => getAction("/sys/dict/treeList", params);
const addDictItem = (params) => postAction("/sys/dictItem/add", params);
const editDictItem = (params) => putAction("/sys/dictItem/edit", params);

//字典标签专用（通过code获取字典数组）
export const ajaxGetDictItems = (code, params) =>
  getAction(`/systemConfig/getDictItems/${code}`, params);
//从缓存中获取字典配置
function getDictItemsFromCache(dictCode) {
  if (
    Vue.ls.get(UI_CACHE_DB_DICT_DATA) &&
    Vue.ls.get(UI_CACHE_DB_DICT_DATA)[dictCode]
  ) {
    let dictItems = Vue.ls.get(UI_CACHE_DB_DICT_DATA)[dictCode];
    console.log(
      "-----------getDictItemsFromCache----------dictCode=" +
        dictCode +
        "---- dictItems=",
      dictItems
    );
    return dictItems;
  }
}

//系统通告
const doReleaseData = (params) =>
  getAction("/sys/annountCement/doReleaseData", params);
const doReovkeData = (params) =>
  getAction("/sys/annountCement/doReovkeData", params);

// 根据部门主键查询用户信息
const queryUserByDepId = (params) =>
  getAction("/sys/user/queryUserByDepId", params);
// 重复校验
const duplicateCheck = (params) => getAction("/sys/duplicate/check", params);
// 加载分类字典
const loadCategoryData = (params) =>
  getAction("/sys/category/loadAllData", params);
const checkRuleByCode = (params) =>
  getAction("/sys/checkRule/checkByCode", params);
//我的通告
const getUserNoticeInfo = (params) =>
  getAction("/sys/sysAnnouncementSend/getMyAnnouncementSend", params);

//功能管理
const addFunction = (params) => postAction("/function/add", params);
const editFunction = (params) => putAction("/function/update", params);


const checkFunction = (params) =>
  getAction("/function/checkIsNameExist", params);
//系统配置
const addSystemConfig = (params) => postAction("/systemConfig/add", params);
const editSystemConfig = (params) => putAction("/systemConfig/update", params);
const checkSystemConfig = (params) =>
  getAction("/systemConfig/checkIsNameExist", params);
const getCurrentSystemConfig = (params) =>
  getAction("/systemConfig/getCurrentInfo", params);
const fileSizeLimit = (params) =>
  getAction("/systemConfig/fileSizeLimit", params);
//用户|角色|模块关系
const addUserBusiness = (params) => postAction("/userBusiness/add", params);
const editUserBusiness = (params) => putAction("/userBusiness/update", params);
const checkUserBusiness = (params) =>
  getAction("/userBusiness/checkIsValueExist", params);
const updateBtnStrByRoleId = (params) =>
  postAction("/userBusiness/updateBtnStr", params);

//修改部分
// 基本资料
//基本资料｜工序管理
const addProcess = (params) => postAction("/process/add", params);
const editProcess = (params) => putAction("/process/update", params);
//基本资料｜错误原因配置
const addDeformityType = (params) => postAction("/deformityType/add", params);
const editDeformityType = (params) =>
  putAction("/deformityType/update", params);
//基本资料｜小组管理
const addWorkerGroup = (params) => postAction("/workerGroup/add", params);
const editWorkerGroup = (params) => putAction("/workerGroup/update", params);
const selectWorkerGroup = (params) => getAction("/selects/workerGroup", params);
//基本资料｜节点管理
const addNodes = (params) => postAction("/nodes/add", params);
const editNodes = (params) => putAction("/nodes/update", params);
//基本资料｜部件管理
const addParts = (params) => postAction("/parts/add", params);
const editParts = (params) => putAction("/parts/update", params);
const checkParts = (params) => postAction("/parts/checkTitle", params);
const getListParts = (params) => getAction("/parts/list", params);
const getQualifiedParts = (params) =>
  getAction("/parts/qualifiedParts", params); //获取满足排产条件部件
//基本资料｜牌号管理
const addBoards = (params) => postAction("/boards/add", params);
const editBoards = (params) => putAction("/boards/update", params);
//基本资料｜版型管理
const addModel = (params) => postAction("/model/add", params);
const editModel = (params) => putAction("/model/update", params);
//基本资料｜供应商管理
const addSupplier = (params) => postAction("/supplier/add", params);
const editSupplier = (params) => putAction("/supplier/update", params);
//基本资料｜工价管理
const addPartLabourCost = (params) => postAction("/partLabourCost/add", params);
const editPartLabourCost = (params) =>
  putAction("/partLabourCost/update", params);
const addLabourCost = (params) => postAction("/labourCost/add", params);
const deleteBatchLabourCost = (params) =>
  deleteAction("/labourCost/deleteBatch", params);

const batchUpdateLabourCost = (params) =>
  postAction("/labourCost/batchUpdate", params);

const labourCostList = (params) => getAction("/labourCost/list", params);
//基本资料｜工资管理
const getWagePriceList = (params) =>
  getAction("/workerWage/getWagePriceList", params); //计件工资明细
const getWageTimeList = (params) =>
  getAction("/workerWage/getWageTimeList", params); //计时工资明细
//项目管理｜项目列表
const addProjects = (params) => postAction("/projects/add", params);
const editProjects = (params) => putAction("/projects/update", params);
const auditProjects = (params) => putAction("/projects/audit", params);
const auditBackProjects = (params) => putAction("/projects/auditBack", params);
const getProjectStatisticalInfos = (params) =>
  getAction("/projects/getProjectStatisticalInfos", params);
//项目管理｜项目列表｜部件管理
const addProjectPart = (params) => postAction("/projectPart/add", params);
const editProjectPart = (params) => putAction("/projectPart/update", params);
const importProjectImageBatch = (params) =>
  postAction("/projectPart/imageMatchBatch", params);
const insertStandardPartsBatch = (params) =>
  postAction("/projectPart/insertStandardPartsBatch", params);

const updateStrategy = (params) => postAction("/account/updateStrategy", params);

//生产管理
//生产管理｜排产管理｜排产批次
const addProductionBatches = (params) =>
  postAction("/productionBatches/createProductionBatches", params);
const addProdWorkerBach = (params) =>
  postAction("/productionBatches/addProdWorkerBach", params);
const updateProdWorkerBach = (params) =>
  postAction("/productionBatches/updateProdWorkerBach", params);
const deleteProdWorkerBach = (params) =>
  deleteAction("/productionBatches/deleteProdWorkerBach", params);

const confirmAsset = (params) =>
  deleteAction("/projectProduction/confirmAsset", params);

const batchConfirmAsset = (params) =>
  deleteAction("/projectProduction/batchConfirmAsset", params);

const updateBatch = (params) =>
  postAction("/productionBatches/updateBatch", params);
const batchDeleteOrders = (params) =>
  postAction("/productionBatches/batchDeleteOrders", params);
//生产管理｜旧版翻新｜排产批次
const createProductionItem = (params) =>
  postAction("/productionBatches/create-batch-with-item", params);

//生产管理｜排产管理｜编辑
const editProjectProduction = (params) =>
  putAction("/projectProduction/update", params);
const separateBillProjectProduction = (params) =>
  putAction("/projectProduction/separateBill", params);
//生产管理｜排产管理｜项目名称列表
const getProjectsNameList = (params) => getAction("/projects/list", params);
//生产管理｜排产管理｜项目部件列表
const getProjectPartList = (params) => getAction("/projectPart/list", params);
//生产管理｜排产批次
const getProductionBatchesDetails = (params) =>
  getAction("/projectProduction/getProjectProductionByIds", params);
const getBatchPrintInfo = (params) =>
  postAction("/productionBatches/batchPrintInfo", params);
const getBatchPrintFinish = (params) =>
  postAction("/productionBatches/batch-finish-print", params);
const distribution = (params) =>
  postAction("/productionBatches/sendOrders", params);
const switchLackStatus = (params) =>
  postAction("/productionBatches/switchLackStatus", params);
const finishBatch = (params) => postAction("/productionBatches/finish", params);
const finishPrintBatch = (params) =>
  postAction("/productionBatches/finish-print", params);
const getProcessList = (params) => getAction("/process/list", params);
const getProcessDetail = (params) =>
  getAction("/productionBatches/detail", params);
const backProdWorkerBach = (params) =>
  deleteAction("/productionBatches/backProdWorkerBach", params);
const backOrders = (params) =>
  postAction("/productionBatches/backOrders", params);
const batchProductionBatches = (params) =>
  deleteAction("/productionBatches/delete", params);
const materialShortageReturn = (params) =>
  putAction("/projectProduction/materialShortageReturn", params);
const batchToPlan = (params) =>
  putAction("/projectProduction/batchToPlan", params);
const returnToPlan = (params) =>
  putAction("/projectProduction/returnToPlan", params);
const batchToFlat = (params) =>
  putAction("/projectProduction/batchToFlat", params);

const createItemBatches = (params) =>
  postAction("/projectProductionItemBatch/createItemBatches", params);
const getDetails = (params) =>
  getAction("/projectProductionItemBatch/getDetails", params);
const finishPrint = (params) =>
  postAction("/projectProductionItemBatch/finish-print", params);
const planRevoke = (params) =>
  postAction("/projectProductionItemBatch/revoke", params);
const createProductionBatches = (params) =>
  postAction("projectProductionItemBatch/createProductionBatches", params);
const projectTitleSelect = (params) =>
  getAction("projectProductionItemBatch/projectTitleSelect", params);
const assetReformUpdate = (params) =>
  putAction("/projectProduction/assetReformUpdate", params);
const partPlanProd = (params) =>
  putAction("/projectProduction/partPlanProd", params);
const getTotalArea = (params) =>
  getAction("/projectProduction/totalArea", params);
const updateItemTitle = (params) =>
  postAction("/projectProduction/updateItemTitle", params);

//生产管理｜工人管理
const addWorker = (params) => postAction("/worker/create", params);
const editWorker = (params) => putAction("/worker/update", params);
const makeWorkerOffline = (params) => putAction("/worker/make-offline", params);
//生产管理｜派工管理
const addDispatch = (params) => postAction("/expatriateWork/create", params);
const editDispatch = (params) => postAction("/expatriateWork/update", params);
const autoDispatch = (params) => postAction("/expatriateWork/auto", params);
//生产管理｜加班管理
const addOvertimeWork = (params) => postAction("/overtimeWork/create", params);
const editOvertimeWork = (params) => postAction("/overtimeWork/update", params);
//生产管理｜项目进度
const getProjectProgress = (params) =>
  getAction("/productionBatches/projectProgress", params);
const batchSendOrders = (params) =>
  postAction("/productionBatches/batchSendOrders", params);
const updateBatchProcess = (params) =>
  postAction("/productionBatches/updateBatchProcess", params);
//仓库管理｜五金耗材
const getHareWareRecord = (params) => getAction("/hareWareRecord/list", params); //五金耗材出入库记录
const addHardwareWarehouse = (params) =>
  postAction("/hardwareWarehouse/add", params); //添加五金耗材
const editHardwareWarehouse = (params) =>
  putAction("/hardwareWarehouse/update", params); //修改五金耗材
const handleInoutHardware = (params) =>
  postAction("/hardwareWarehouse/inoutHardware", params); //五金耗材出入库
//仓库管理｜型材库
const getProximateWarehouse = (params) =>
  getAction("/proximateRecord/list", params); //型材出入库记录
const addProximateWarehouse = (params) =>
  postAction("/proximateWarehouse/add", params); //添加型材
const editProximateWarehouse = (params) =>
  putAction("/proximateWarehouse/update", params); //修改型材
const handleProximateWarehouse = (params) =>
  postAction("/proximateWarehouse/inoutProximate", params); //型材出入库
//辅材管理
//辅材配置
const getAuxiliaryDisposeSelect = (params) =>
  getAction("/auxiliary-dispose/list-select", params); //成品辅材出入库记录
const addAuxiliaryDispose = (params) =>
  postAction("/auxiliary-dispose/add", params);
const editAuxiliaryDispose = (params) =>
  putAction("/auxiliary-dispose/update", params);
//辅材成品
const getAuxiliaryRecord = (params) =>
  getAction("/auxiliary-prod-inout/list", params); //成品辅材出入库记录
const addAuxiliary = (params) => postAction("/auxiliary-prod/add", params); //添加五金耗材
const editAuxiliary = (params) => putAction("/auxiliary-prod/update", params); //修改五金耗材
const handleInoutAuxiliary = (params) =>
  postAction("/auxiliary-prod/inoutAuxiliary", params); //五金耗材出入库
//辅材待翻新
const getAuxiliaryRefurbishmentRecord = (params) =>
  getAction("/auxiliary-refurbishment-inout/list", params); //待翻新辅材出入库记录
const addAuxiliaryRefurbishment = (params) =>
  postAction("/auxiliary-refurbishment/add", params); //添加五金耗材
const editAuxiliaryRefurbishment = (params) =>
  putAction("/auxiliary-refurbishment/update", params); //添加五金耗材

const handleInoutAuxiliaryRefurbishment = (params) =>
  postAction("/auxiliary-refurbishment/inoutAuxiliary", params); //五金耗材出入库
// 资产管理｜资产列表
const getAssetRecordList = (params) => getAction("/asset/recordList", params);
const addAsset = (params) => postAction("/asset/add", params);
const editAsset = (params) => putAction("/asset/update", params);
const inoutAsset = (params) => postAction("/asset/inout", params);
const inoutAssets = (params) => postAction("/asset/inoutAssets", params);
const splitAssetRecord = (params) =>
  postAction("/asset/splitAssetRecord", params);

const batchImportExcelAsset = (params) =>
  postAction("/asset/batchImportExcel", params);
const exportRepairExcel = (params) =>
  postAction("/asset/exportRepairExcel", params);
const rollbackAssetBatch = (params) =>
  postAction("/asset-batch/rollback", params);
const getAssetBatchDetail = (params) =>
  getAction("/asset-batch/detail", params);
const getAssetBatchStatistics = (params) =>
  getAction("/asset-batch/statistics", params);
const getReceivedStatistics = (params) =>
  getAction("/asset-received-record/statistics", params);
const getAssetReceivedRecord = (params) =>
  getAction("/asset-received-record/asset", params);
const createAssetReceivedRecord = (params) =>
  postAction("/asset-received-record/create", params);
const getAreaStatistics = (params) =>
  getAction("/asset/areaStatistics", params);

// 资产管理｜生产平库
const projectPartBatchFlatLibrary = (params) =>
  getAction("/projectPart/batchFlatLibrary", params);
const againBatchFlatLibrary = (params) =>
  getAction("/projectPart/againBatchFlatLibrary", params);
const flatCheckAsset = (params) => postAction("/asset/flatCheck", params);

// 补单平库 [废弃]
const projectPartReplacementFlatLibrary = (params) =>
  postAction("/projectPart/replacementFlatLibrary", params);
//下拉选项
const selectsProject = (params) => getAction("/selects/project", params);
const selectsNodes = (params) => getAction("/selects/nodes", params);
const selectsWorker = (params) => getAction("/selects/worker", params);
const selectsAllWorker = (params) => getAction("/worker/list", params);

const selectsUser = (params) => getAction("/selects/user", params);
const selectsProcess = (params) => getAction("/selects/process", params);
const selectsBoards = (params) => getAction("/selects/boards", params);
const selectsPartType = (params) => getAction("/selects/partType", params);
const selectsModel = (params) => getAction("/selects/model", params);
const selectsSupplier = (params) => getAction("/selects/supplier", params);
const selectsProjectBulid = (params) =>
  getAction("/selects/originProjectBuild", params);
const selectBannerType = (params) =>
  getAction("/selects/largeScreenBannerType", params);
// 配件类型
const selectAccessory = (params) => getAction("/selects/accessory", params);

//补单相关
const projectPartRepairCreate = (params) =>
  postAction("/projectPartRepair/create", params);
const projectPartRepairUpdate = (params) =>
  postAction("/projectPartRepair/update", params);
const auditProjectsRepair = (params) =>
  putAction("/projectPartRepair/audit", params);
const auditBackProjectsRepair = (params) =>
  putAction("/projectPartRepair/auditBack", params);

// 铝件批次
const loadProjectPart = (params) => getAction("/projectPart/info", params);
const updateHaveProd = (params) =>
  putAction("/projectPart/updateHaveProd", params);

// 修改加工备注
const updateMachiningRemark = (params) =>
  putAction("/asset/updateMachiningRemark", params);
//修改新旧材料
const updateNewOrOld = (params) => putAction("/asset/updateNewOrOld", params);

const getFlatList = (params) => getAction("/asset/flatList", params);

// 资产选择
const getAssetSelect = (params) => getAction("/asset/select", params);

// 接单记录 数据统计
const orderStatistics = (params) =>
  getAction("/productionWorkerBatch/statistics", params);
const orderNode = (params) =>
  getAction("/productionWorkerBatch/geiNodesByProjectIdOrRepairId", params);

const getHandelDetail = (params) =>
  getAction("/productionWorkerBatch/getHandelDetail", params);

// 附件类型
const getEnclosureTypes = (params) =>
  getAction("/selects/enclosureTypes", params);

// 项目节点
const getProjectPartNodes = (params) =>
  getAction("/selects/projectPartNodes", params);

// 出入库批次类型
const getAssetRecordTypes = (params) =>
  getAction("/selects/assetRecordTypes", params);
//轮播图
const addLargeScreenBanner = (params) =>
  postAction("/largeScreenBanner/add", params);
const editLargeScreenBanner = (params) =>
  putAction("/largeScreenBanner/update", params);
const getBrowseStatistic = (params) =>
  getAction("/browseRecord/statistics", params); //浏览记录统计
const addBrowseRecord = (params) => postAction("/browseRecord/add", params); //新增
const selectCurrentOrgList = (params) => getAction("/select/addUserOrgSelect", params);

const setCountDown = (params) => getAction("/countDown/set", params);

// 板型
const getModelList = (params) => getAction("/model/list", params);
export {
  getBuyAndSaleStatistics,
  buyOrSalePrice,
  checkTenant,
  editTenant,
  getTenantSelect,
  addRole,
  editRole,
  checkRole,
  findUserRole,
  registerUser,
  addUser,
  editUser,
  getUserList,
  queryall,
  checkOnlyUser,
  changePassword,
  getPermissionList,
  addPermission,
  editPermission,
  queryTreeList,
  queryListAsync,
  queryRolePermission,
  saveRolePermission,
  queryPermissionsByUser,
  loadAllRoleIds,
  getPermissionRuleList,
  queryPermissionRule,
  queryOrganizationTreeList,
  queryOrganizationById,
  checkOrganization,
  queryParentName,
  searchByKeywords,
  deleteByDepartId,
  deleteLog,
  deleteLogList,
  addDict,
  editDict,
  treeList,
  addDictItem,
  editDictItem,
  doReleaseData,
  doReovkeData,
  queryUserByDepId,
  duplicateCheck,
  queryTreeListForRole,
  getSystemMenuList,
  getSystemSubmenu,
  getSystemSubmenuBatch,
  loadCategoryData,
  checkRuleByCode,
  queryDepartPermission,
  saveDepartPermission,
  queryTreeListForDeptRole,
  queryDeptRolePermission,
  saveDeptRolePermission,
  queryMyDepartTreeList,
  getUserNoticeInfo,
  getDictItemsFromCache,
  addFunction,
  editFunction,
  checkFunction,
  addSystemConfig,
  editSystemConfig,
  checkSystemConfig,
  getCurrentSystemConfig,
  fileSizeLimit,
  addUserBusiness,
  editUserBusiness,
  checkUserBusiness,
  updateBtnStrByRoleId,
  //修改部分
  addProcess,
  editProcess,
  addNodes,
  editNodes,
  addBoards,
  editBoards,
  addModel,
  editModel,
  addParts,
  editParts,
  checkParts,
  getListParts,
  addProjects,
  editProjects,
  auditProjects,
  auditBackProjects,
  auditProjectsRepair,
  auditBackProjectsRepair,
  getProjectStatisticalInfos,
  getProjectsNameList,
  getProjectPartList,
  addWorker,
  editWorker,
  makeWorkerOffline,
  addDispatch,
  editDispatch,
  autoDispatch,
  addOvertimeWork,
  editOvertimeWork,
  getProjectProgress,
  batchSendOrders,
  updateBatchProcess,
  selectsProject,
  selectsProjectBulid,
  selectsNodes,
  selectsWorker,
  selectsAllWorker,
  selectsUser,
  selectsProcess,
  selectsBoards,
  selectsPartType,
  selectsModel,
  selectBannerType,
  selectAccessory,
  addProductionBatches,
  addProdWorkerBach,
  updateProdWorkerBach,
  deleteProdWorkerBach,
  updateBatch,
  batchDeleteOrders,
  getProductionBatchesDetails,
  distribution,
  switchLackStatus,
  finishBatch,
  finishPrintBatch,
  createProductionBatches,
  projectTitleSelect,
  getProcessDetail,
  backProdWorkerBach,
  backOrders,
  batchProductionBatches,
  materialShortageReturn,
  batchToPlan,
  batchToFlat,
  createItemBatches,
  getDetails,
  finishPrint,
  assetReformUpdate,
  partPlanProd,
  getTotalArea,
  updateItemTitle,
  getBatchPrintInfo,
  getBatchPrintFinish,
  addPartLabourCost,
  editPartLabourCost,
  addLabourCost,
  deleteBatchLabourCost,
  batchUpdateLabourCost,
  labourCostList,
  editProjectProduction,
  separateBillProjectProduction,
  getProcessList,
  getHareWareRecord,
  addHardwareWarehouse,
  editHardwareWarehouse,
  handleInoutHardware,
  getQualifiedParts,
  getProximateWarehouse,
  addProximateWarehouse,
  editProximateWarehouse,
  handleProximateWarehouse,
  getWagePriceList,
  getWageTimeList,
  addProjectPart,
  editProjectPart,
  insertStandardPartsBatch,
  getAssetRecordList,
  addAsset,
  editAsset,
  inoutAsset,
  inoutAssets,
  batchImportExcelAsset,
  exportRepairExcel,
  rollbackAssetBatch,
  getAssetBatchDetail,
  getAssetBatchStatistics,
  getReceivedStatistics,
  getAssetReceivedRecord,
  createAssetReceivedRecord,
  getAreaStatistics,
  projectPartBatchFlatLibrary,
  againBatchFlatLibrary,
  flatCheckAsset,
  splitAssetRecord,
  projectPartReplacementFlatLibrary,
  projectPartRepairCreate,
  projectPartRepairUpdate,
  importProjectImageBatch,
  confirmAsset,
  batchConfirmAsset,
  addSupplier,
  editSupplier,
  selectsSupplier,
  getAuxiliaryDisposeSelect,
  addAuxiliaryDispose,
  editAuxiliaryDispose,
  createProductionItem,
  getAuxiliaryRecord,
  addAuxiliary,
  editAuxiliary,
  handleInoutAuxiliary,
  getAuxiliaryRefurbishmentRecord,
  addAuxiliaryRefurbishment,
  editAuxiliaryRefurbishment,
  handleInoutAuxiliaryRefurbishment,
  addDeformityType,
  editDeformityType,
  addWorkerGroup,
  editWorkerGroup,
  selectWorkerGroup,
  loadProjectPart,
  updateHaveProd,
  updateMachiningRemark,
  updateNewOrOld,
  getFlatList,
  getAssetSelect,
  orderStatistics,
  orderNode,
  getHandelDetail,
  getEnclosureTypes,
  getProjectPartNodes,
  getAssetRecordTypes,
  addLargeScreenBanner,
  editLargeScreenBanner,
  planRevoke,
  returnToPlan,
  getBrowseStatistic,
  addBrowseRecord,
  getModelList,
  selectCurrentOrgList,
  setCountDown,
  updateStrategy
};

<template>
  <div id="userLayout" :class="['user-layout-wrapper', device]">
    <div class="container">
      <div class="top">
        <div class="header">
          <a-row>
            <a-col>
              <a href="/" class="title-main">
                <div class="title">{{ systemTitle }}</div>
                <!-- <small class="desc"></small> -->
              </a>
            </a-col>
          </a-row>
        </div>
      </div>
      <route-view></route-view>
      <!-- <div class="login-footer">
        技术支持
      </div> -->
    </div>

    <div class="sys_logo">
      <img
        src="@/assets/logo.png"
        alt=""
        srcset=""
        style="width: 50px;height:50px;margin-right: 20px;"
      />
    </div>
  </div>
</template>

<script>
import RouteView from "@/components/layouts/RouteView";
import { mixinDevice } from "@/utils/mixin.js";

export default {
  name: "UserLayout",
  components: { RouteView },
  mixins: [mixinDevice],
  data() {
    return {
      systemTitle: window.SYS_TITLE,
    };
  },
  mounted() {
    document.body.classList.add("userLayout");
  },
  beforeDestroy() {
    document.body.classList.remove("userLayout");
  },
};
</script>

<style lang="less" scoped>
#userLayout.user-layout-wrapper {
  height: 100%;

  &.mobile {
    .container {
      .main {
        width: 80%;
        margin: 0 10%;
        @media (min-width: 1001px) {
          max-width: 375px;
        }
      }
    }
  }

  .container {
    width: 100%;
    min-height: 100%;
    // padding: 120px 0 144px;
    position: relative;
    background: url("~@/assets/bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center 1px;

    a {
      text-decoration: none;
    }

    .top {
      .header {
        .ant-row .title-main {
          @media (max-width: 1000px) {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        .title {
          width: calc(33vw - 20px);
          font-size: 60px;
          color: #18aa50;
          // background-color: #fff;
          font-family: "Chinese Quote", -apple-system, BlinkMacSystemFont,
            "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei",
            "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji",
            "Segoe UI Emoji", "Segoe UI Symbol";
          font-weight: 700;
          position: relative;
          top: 2px;
          margin: 0 auto;
          margin-top: 10%;
          padding-top: 20px;
          display: flex;
          align-items: center;
          justify-content: center;

          border: 1.4px solid rgba(39, 145, 29, 0);
          border-bottom: 0;
          background: linear-gradient(
            254deg,
            rgba(37, 190, 106, 0.4) 4.25%,
            rgba(32, 194, 107, 0.4) 118.86%
          );
          box-shadow: 0px 20px 40px 0px rgba(13, 96, 38, 0.5);
          backdrop-filter: blur(30px);
          @media (max-width: 1600px) {
            font-size: 50px;
          }
          // padding-left: 285px;
          @media (max-width: 1000px) {
            font-size: 32px;
          }
        }
        .desc {
          font-size: 16px;
          color: #666;
          margin-top: 12px;
          margin-left: 10px;
          margin-bottom: 40px;
        }
      }
    }

    .main {
      width: calc(33vw - 20px);
      margin: 0 auto;
      padding: 2% 0;
      min-width: 260px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1.4px solid rgba(40, 21, 155, 0);
      border-top: 0;
      background: linear-gradient(
        254deg,
        rgba(22, 22, 219, 0.4) 4.25%,
        rgba(44, 14, 239, 0.4) 118.86%
      );
      box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(30px);
      // background-color: #fff;
      @media (max-width: 1600px) {
        font-size: 50px;
      }
      @media (max-width: 1000px) {
        margin-left: 0;
        width: 200px;
      }
    }

    .footer {
      position: absolute;
      width: 100%;
      bottom: 0;
      padding: 0 16px;
      margin: 48px 0 24px;
      text-align: center;

      .links {
        margin-bottom: 8px;
        font-size: 14px;
        a {
          color: rgba(0, 0, 0, 0.45);
          transition: all 0.3s;
          &:not(:last-child) {
            margin-right: 40px;
          }
        }
      }
      .copyright {
        color: rgba(0, 0, 0, 0.45);
        font-size: 14px;
      }
    }
  }
}
.login-footer {
  position: fixed;
  // left: 0;
  right: 0;
  bottom: 20px;
  width: 33vw;
  // color: thistle;
  color: #333;
  font-size: large;
  text-align: right;
  text-align: center;
  @media (max-width: 1000px) {
    font-size: 12px;
  }
}

.sys_logo {
  position: fixed;
  top: 30px;
  left: 60px;
  z-index: 1;
}
</style>

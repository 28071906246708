<template>
  <div class="logo">
    <h1 v-if="showTitle">
      <img
        src="@/assets/logo.png"
        alt=""
        srcset=""
        style="margin-right: 10px;"
      />
      <span v-if="opened">{{ title }}</span>
    </h1>
  </div>
</template>

<script>
import { mixin } from "@/utils/mixin.js";

export default {
  name: "Logo",
  mixins: [mixin],
  props: {
    title: {
      type: String,
      default: window.SYS_TITLE,
      required: false,
    },
    showTitle: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  computed: {
    opened() {
      return this.$store.state.app.sidebar.opened;
    },
  },
};
</script>
<style lang="less" scoped>
/*缩小首頁布 局顶部的高度*/
@height: 59px;

.sider {
  box-shadow: none !important;
  .logo {
    color: #68de69;
    height: @height !important;
    line-height: @height !important;
    box-shadow: none !important;
    transition: background 300ms;
    background: #31b37f;
    h1 {
      font-size: 22px;
      color: #ffffff;
    }
    a {
      color: white;
      &:hover {
        color: rgba(255, 255, 255, 0.8);
      }
    }
  }

  &.light .logo {
    background-color: @primary-color;
  }
}
</style>

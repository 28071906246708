import * as api from "@/api/api";
import { isURL } from "@/utils/validate";
import Vue from "vue";

export function getFileType(fileName) {
  try {
    const fileArr = fileName.split(".");
    const fileType = fileArr[fileArr.length - 1];
    const formExtArr = ["xls", "xlsx"];
    if (formExtArr.includes(fileType)) {
      return 1;
    }
    const docExtArr = ["txt", "doc"];
    if (docExtArr.includes(fileType)) {
      return 2;
    }
    const voiceExtArr = ["mpeg", "mp3", "mpeg-4", "midi", "wma"];
    if (voiceExtArr.includes(fileType)) {
      return 3;
    }
    const videoExtArr = [
      "avi",
      "wmv",
      "mpg",
      "mpeg",
      "mov",
      "rm",
      "ram",
      "swf",
      "flv",
      "mp4",
    ];
    if (videoExtArr.includes(fileType)) {
      return 4;
    }
    const imgExtArr = ["webp", "wmf", "jpg", "bmp", "gif", "jpeg", "png"];
    if (imgExtArr.includes(fileType)) {
      return 5;
    }
    return 0;
  } catch (e) {
    return 0;
  }
}

export function timeFix() {
  const time = new Date();
  const hour = time.getHours();
  return hour < 9
    ? "早上好"
    : hour <= 11
    ? "上午好"
    : hour <= 13
    ? "中午好"
    : hour < 20
    ? "下午好"
    : "晚上好";
}

export function welcome() {
  const arr = [
    "休息一会儿吧",
    "准备吃什么呢?",
    "要不要打一把 DOTA",
    "我猜你可能累了",
  ];
  let index = Math.floor(Math.random() * arr.length);
  return arr[index];
}

/**
 * 触发 window.resize
 */
export function triggerWindowResizeEvent() {
  let event = document.createEvent("HTMLEvents");
  event.initEvent("resize", true, true);
  event.eventType = "message";
  window.dispatchEvent(event);
}

/**
 * 过滤对象中为空的属性
 * @param obj
 * @returns {*}
 */
export function filterObj(obj) {
  if (!(typeof obj == "object")) {
    return;
  }

  for (let key in obj) {
    if (
      obj.hasOwnProperty(key) &&
      (obj[key] == null || obj[key] == undefined || obj[key] === "")
    ) {
      delete obj[key];
    }
  }
  return obj;
}

/**
 * 时间格式化
 * @param value
 * @param fmt
 * @returns {*}
 */
export function formatDate(value, fmt) {
  let regPos = /^\d+(\.\d+)?$/;
  if (regPos.test(value)) {
    //如果是数字
    let getDate = new Date(value);
    let o = {
      "M+": getDate.getMonth() + 1,
      "d+": getDate.getDate(),
      "h+": getDate.getHours(),
      "m+": getDate.getMinutes(),
      "s+": getDate.getSeconds(),
      "q+": Math.floor((getDate.getMonth() + 3) / 3),
      S: getDate.getMilliseconds(),
    };
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        (getDate.getFullYear() + "").substr(4 - RegExp.$1.length)
      );
    }
    for (let k in o) {
      if (new RegExp("(" + k + ")").test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          RegExp.$1.length === 1
            ? o[k]
            : ("00" + o[k]).substr(("" + o[k]).length)
        );
      }
    }
    return fmt;
  } else {
    //TODO
    value = value.trim();
    return value.substr(0, fmt.length);
  }
}

// 生成首頁路由
export function generateIndexRouter(data) {
  let indexRouter = generateChildRouters(data);

  indexRouter.splice(0, 0, {
    path: "/databoard",
    name: "数据看板",
    component: () => import("@/components/layouts/TabLayout"),
    meta: {
      title: "首頁",
      icon: "icon-present",
      url: "/databoard/show",
    },
    redirect: "/databoard/show",
  });
  indexRouter.splice(0, 0, {
    path: "/",
    name: "首頁",
    component: () => import("@/components/layouts/TabLayout"),
    meta: {
      title: "首頁",
      icon: "icon-present",
      url: "/dashboard/analysis",
    },
    redirect: "/dashboard/analysis",
  });
  return indexRouter;
}

// 生成嵌套路由（子路由）

function generateChildRouters(data) {
  const routers = [];
  for (let item of data) {
    let componentPath = "";
    item.route = "1";
    if (item.component.indexOf("layouts") >= 0) {
      componentPath = () => import("@/components" + item.component);
    } else {
      componentPath = () => import("@/views" + item.component);
    }
    // eslint-disable-next-line
    let URL = (item.url || "").replace(/{{([^}}]+)?}}/g, (s1, s2) => eval(s2)); // URL支持{{ window.xxx }}占位符变量
    if (isURL(URL)) {
      item.url = URL;
    }
    let menu = {
      path: item.url,
      name: item.text,
      component: componentPath,
      meta: {
        title: item.text,
        icon: item.icon,
        url: item.url,
        // permissionList:"",
        // keepAlive:"",
      },
    };
    if (item.children && item.children.length > 0) {
      menu.children = [...generateChildRouters(item.children)];
    }
    //--update-begin----author:scott---date:20190320------for:根据后台菜单配置，判断是否路由菜单字段，动态选择是否生成路由（为了支持参数URL菜单）------
    //判断是否生成路由
    if (item.route && item.route === "0") {
      //console.log(' 不生成路由 item.route：  '+item.route);
      //console.log(' 不生成路由 item.path：  '+item.path);
    } else {
      routers.push(menu);
    }
    //--update-end----author:scott---date:20190320------for:根据后台菜单配置，判断是否路由菜单字段，动态选择是否生成路由（为了支持参数URL菜单）------
  }
  return routers;
}

/**
 * 深度克隆对象、数组
 * @param obj 被克隆的对象
 * @return 克隆后的对象
 */
export function cloneObject(obj) {
  return JSON.parse(JSON.stringify(obj));
}

/**
 * 随机生成数字
 *
 * 示例：生成长度为 12 的随机数：randomNumber(12)
 * 示例：生成 3~23 之间的随机数：randomNumber(3, 23)
 *
 * @param1 最小值 | 长度
 * @param2 最大值
 * @return int 生成后的数字
 */
export function randomNumber() {
  // 生成 最小值 到 最大值 区间的随机数
  const random = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };
  if (arguments.length === 1) {
    let [length] = arguments;
    // 生成指定长度的随机数字，首位一定不是 0
    let nums = [...Array(length).keys()].map((i) =>
      i > 0 ? random(0, 9) : random(1, 9)
    );
    return parseInt(nums.join(""));
  } else if (arguments.length >= 2) {
    let [min, max] = arguments;
    return random(min, max);
  } else {
    return Number.NaN;
  }
}

/**
 * 随机生成字符串
 * @param length 字符串的长度
 * @param chats 可选字符串区间（只会生成传入的字符串中的字符）
 * @return string 生成的字符串
 */
export function randomString(length, chats) {
  if (!length) length = 1;
  if (!chats) chats = "0123456789qwertyuioplkjhgfdsazxcvbnm";
  let str = "";
  for (let i = 0; i < length; i++) {
    let num = randomNumber(0, chats.length - 1);
    str += chats[num];
  }
  return str;
}

/**
 * 随机生成uuid
 * @return string 生成的uuid
 */
export function randomUUID() {
  let chats = "0123456789abcdef";
  return randomString(32, chats);
}

/**
 * 下划线转驼峰
 * @param string
 * @returns {*}
 */
export function underLine2CamelCase(string) {
  return string.replace(/_([a-z])/g, function(all, letter) {
    return letter.toUpperCase();
  });
}

/**
 * 判断是否显示办理按钮
 * @param bpmStatus
 * @returns {*}
 */
export function showDealBtn(bpmStatus) {
  if (bpmStatus != "1" && bpmStatus != "3" && bpmStatus != "4") {
    return true;
  }
  return false;
}

/**
 * 增强CSS，可以在页面上输出全局css
 * @param css 要增强的css
 * @param id style标签的id，可以用来清除旧样式
 */
export function cssExpand(css, id) {
  let style = document.createElement("style");
  style.type = "text/css";
  style.innerHTML = `@charset "UTF-8"; ${css}`;
  // 清除旧样式
  if (id) {
    let $style = document.getElementById(id);
    if ($style != null) $style.outerHTML = "";
    style.id = id;
  }
  // 应用新样式
  document.head.appendChild(style);
}

/** 用于js增强事件，运行JS代码，可以传参 */
// options 所需参数：
//    参数名         类型            说明
//    vm             VueComponent    vue实例
//    event          Object          event对象
//    jsCode         String          待执行的js代码
//    errorMessage   String          执行出错后的提示（控制台）
export function jsExpand(options = {}) {
  // 绑定到window上的keyName
  let windowKeyName = "J_CLICK_EVENT_OPTIONS";
  if (typeof window[windowKeyName] != "object") {
    window[windowKeyName] = {};
  }

  // 随机生成JS增强的执行id，防止冲突
  let id = randomString(16, "qwertyuioplkjhgfdsazxcvbnm".toUpperCase());
  // 封装按钮点击事件
  let code = `
    (function (o_${id}) {
      try {
        (function (globalEvent, vm) {
          ${options.jsCode}
        })(o_${id}.event, o_${id}.vm)
      } catch (e) {
        o_${id}.error(e)
      }
      o_${id}.done()
    })(window['${windowKeyName}']['EVENT_${id}'])
  `;
  // 创建script标签
  const script = document.createElement("script");
  // 将需要传递的参数挂载到window对象上
  window[windowKeyName]["EVENT_" + id] = {
    vm: options.vm,
    event: options.event,
    // 当执行完成时，无论如何都会调用的回调事件
    done() {
      // 执行完后删除新增的 script 标签不会撤销执行结果（已产生的结果不会被撤销）
      script.outerHTML = "";
      delete window[windowKeyName]["EVENT_" + id];
    },
    // 当js运行出错的时候调用的事件
    error(e) {
      console.group(
        `${options.errorMessage ||
          "用户自定义JS增强代码运行出错"}（${new Date()}）`
      );
      console.error(e);
      console.groupEnd();
    },
  };
  // 将事件挂载到document中
  script.innerHTML = code;
  document.body.appendChild(script);
}

/**
 * 重复值验证工具方法
 *
 * 使用示例：
 * { validator: (rule, value, callback) => validateDuplicateValue('sys_fill_rule', 'rule_code', value, this.model.id, callback) }
 *
 * @param tableName 被验证的表名
 * @param fieldName 被验证的字段名
 * @param fieldVal 被验证的值
 * @param dataId 数据ID，可空
 * @param callback
 */
export function validateDuplicateValue(
  tableName,
  fieldName,
  fieldVal,
  dataId,
  callback
) {
  if (fieldVal) {
    let params = { tableName, fieldName, fieldVal, dataId };
    api
      .duplicateCheck(params)
      .then((res) => {
        res["success"] ? callback() : callback(res["message"]);
      })
      .catch((err) => {
        callback(err.message || err);
      });
  } else {
    callback();
  }
}

/**
 * 根据编码校验规则code，校验传入的值是否合法
 *
 * 使用示例：
 * { validator: (rule, value, callback) => validateCheckRule('common', value, callback) }
 *
 * @param ruleCode 编码校验规则 code
 * @param value 被验证的值
 * @param callback
 */
export function validateCheckRule(ruleCode, value, callback) {
  if (ruleCode && value) {
    value = encodeURIComponent(value);
    api
      .checkRuleByCode({ ruleCode, value })
      .then((res) => {
        res["success"] ? callback() : callback(res["message"]);
      })
      .catch((err) => {
        callback(err.message || err);
      });
  } else {
    callback();
  }
}

/**
 * 如果值不存在就 push 进数组，反之不处理
 * @param array 要操作的数据
 * @param value 要添加的值
 * @param key 可空，如果比较的是对象，可能存在地址不一样但值实际上是一样的情况，可以传此字段判断对象中唯一的字段，例如 id。不传则直接比较实际值
 * @returns {boolean} 成功 push 返回 true，不处理返回 false
 */
export function pushIfNotExist(array, value, key) {
  for (let item of array) {
    if (key && item[key] === value[key]) {
      return false;
    } else if (item === value) {
      return false;
    }
  }
  array.push(value);
  return true;
}

/**
 * 可用于判断是否成功
 * @type {symbol}
 */
export const succeedSymbol = Symbol();
/**
 * 可用于判断是否失败
 * @type {symbol}
 */
export const failedSymbol = Symbol();

/**
 * 使 promise 无论如何都会 resolve，除非传入的参数不是一个Promise对象或返回Promise对象的方法
 * 一般用在 Promise.all 中
 *
 * @param promise 可传Promise对象或返回Promise对象的方法
 * @returns {Promise<any>}
 */
export function alwaysResolve(promise) {
  return new Promise((resolve, reject) => {
    let p = promise;
    if (typeof promise === "function") {
      p = promise();
    }
    if (p instanceof Promise) {
      p.then((data) => {
        resolve({ type: succeedSymbol, data });
      }).catch((error) => {
        resolve({ type: failedSymbol, error });
      });
    } else {
      reject(
        "alwaysResolve: 传入的参数不是一个Promise对象或返回Promise对象的方法"
      );
    }
  });
}

/**
 * 简单实现防抖方法
 *
 * 防抖(debounce)函数在第一次触发给定的函数时，不立即执行函数，而是给出一个期限值(delay)，比如100ms。
 * 如果100ms内再次执行函数，就重新开始计时，直到计时结束后再真正执行函数。
 * 这样做的好处是如果短时间内大量触发同一事件，只会执行一次函数。
 *
 * @param fn 要防抖的函数
 * @param delay 防抖的毫秒数
 * @returns {Function}
 */
export function simpleDebounce(fn, delay = 100) {
  let timer = null;
  return function() {
    let args = arguments;
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      fn.apply(null, args);
    }, delay);
  };
}

/**
 * 不用正则的方式替换所有值
 * @param text 被替换的字符串
 * @param checker  替换前的内容
 * @param replacer 替换后的内容
 * @returns {String} 替换后的字符串
 */
export function replaceAll(text, checker, replacer) {
  let lastText = text;
  text = text.replace(checker, replacer);
  if (lastText !== text) {
    return replaceAll(text, checker, replacer);
  }
  return text;
}

/**
 * 转换商品扩展字段的格式
 * @param thisRows
 * @param checker
 * @param replacer
 * @returns {string}
 */
export function getMpListShort(thisRows, checker, replacer) {
  let mPropertyListShort = "";
  let nativeNameStr = "";
  for (let i = 0; i < thisRows.length; i++) {
    if (thisRows[i].enabled) {
      nativeNameStr += thisRows[i].nativeName + ",";
    }
  }
  if (nativeNameStr) {
    mPropertyListShort = nativeNameStr.substring(0, nativeNameStr.length - 1);
  }
  return mPropertyListShort;
}

/**
 * js获取当前月份， 格式“yyyy-MM”
 */
export function getNowFormatMonth() {
  var date = new Date();
  var seperator1 = "-";
  var month = date.getMonth() + 1;
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  var currentdate = date.getFullYear() + seperator1 + month;
  return currentdate;
}

/**
 * js获取当前日期， 格式“yyyy-MM-dd”
 */
export function getFormatDate() {
  var date = new Date();
  var seperator1 = "-";
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var strDate = date.getDate();
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = "0" + strDate;
  }
  var currentdate = year + seperator1 + month + seperator1 + strDate;
  return currentdate;
}

/**
 * js获取当前时间， 格式“yyyy-MM-dd HH:MM:SS”
 */
export function getNowFormatDateTime() {
  var date = new Date();
  var seperator1 = "-";
  var seperator2 = ":";
  var month = date.getMonth() + 1;
  var strDate = date.getDate();
  var strHours = date.getHours();
  var strMinutes = date.getMinutes();
  var strSeconds = date.getSeconds();
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = "0" + strDate;
  }
  if (strHours >= 0 && strHours <= 9) {
    strHours = "0" + strHours;
  }
  if (strMinutes >= 0 && strMinutes <= 9) {
    strMinutes = "0" + strMinutes;
  }
  if (strSeconds >= 0 && strSeconds <= 9) {
    strSeconds = "0" + strSeconds;
  }
  var currentdate =
    date.getFullYear() +
    seperator1 +
    month +
    seperator1 +
    strDate +
    " " +
    strHours +
    seperator2 +
    strMinutes +
    seperator2 +
    strSeconds;
  return currentdate;
}

/**
 * JS中根据指定值删除数组中的元素
 * @param arrylist
 * @param val
 */
export function removeByVal(arrylist, val) {
  for (var i = 0; i < arrylist.length; i++) {
    if (arrylist[i] == val) {
      arrylist.splice(i, 1);
      break;
    }
  }
}

/**
 * 将数组单个金额中的数值转为负数
 * @param arr
 * @returns {Array}
 */
export function changeListFmtMinus(arr) {
  var newArr = new Array();
  for (var i = 0; i < arr.length; i++) {
    if (arr[i] < 0) {
      newArr.push((arr[i] - 0).toString());
    } else {
      newArr.push((0 - arr[i]).toString());
    }
  }
  return newArr;
}
/**
 * floatObj 包含加减乘除四个方法，能确保浮点数运算不丢失精度
 *
 * 我们知道计算机编程语言里浮点数计算会存在精度丢失问题（或称舍入误差），其根本原因是二进制和实现位数限制有些数无法有限表示
 * 以下是十进制小数对应的二进制表示
 *      0.1 >> 0.0001 1001 1001 1001…（1001无限循环）
 *      0.2 >> 0.0011 0011 0011 0011…（0011无限循环）
 * 计算机里每种数据类型的存储是一个有限宽度，比如 JavaScript 使用 64 位存储数字类型，因此超出的会舍去。舍去的部分就是精度丢失的部分。
 *
 * ** method **
 *  add / subtract / multiply /divide
 *
 * ** explame **
 *  0.1 + 0.2 == 0.30000000000000004 （多了 0.00000000000004）
 *  0.2 + 0.4 == 0.6000000000000001  （多了 0.0000000000001）
 *  19.9 * 100 == 1989.9999999999998 （少了 0.0000000000002）
 *
 * floatObj.add(0.1, 0.2) >> 0.3
 * floatObj.multiply(19.9, 100) >> 1990
 *
 */
export function floatObj() {
  /*
   * 判断obj是否为一个整数
   */
  function isInteger(obj) {
    return Math.floor(obj) === obj;
  }

  /*
   * 将一个浮点数转成整数，返回整数和倍数。如 3.14 >> 314，倍数是 100
   * @param floatNum {number} 小数
   * @return {object}
   *   {times:100, num: 314}
   */
  function toInteger(floatNum) {
    var ret = { times: 1, num: 0 };
    if (isInteger(floatNum)) {
      ret.num = floatNum;
      return ret;
    }
    var strfi = floatNum + "";
    var dotPos = strfi.indexOf(".");
    var len = strfi.substr(dotPos + 1).length;
    var times = Math.pow(10, len);
    var intNum = parseInt(floatNum * times + 0.5, 10);
    ret.times = times;
    ret.num = intNum;
    return ret;
  }

  /*
   * 核心方法，实现加减乘除运算，确保不丢失精度
   * 思路：把小数放大为整数（乘），进行算术运算，再缩小为小数（除）
   *
   * @param a {number} 运算数1
   * @param b {number} 运算数2
   * @param digits {number} 精度，保留的小数点数，比如 2, 即保留为两位小数
   * @param op {string} 运算类型，有加减乘除（add/subtract/multiply/divide）
   *
   */
  function operation(a, b, digits = 3, op) {
    var o1 = toInteger(a);
    var o2 = toInteger(b);
    var n1 = o1.num;
    var n2 = o2.num;
    var t1 = o1.times;
    var t2 = o2.times;
    var max = t1 > t2 ? t1 : t2;
    var result = null;
    switch (op) {
      case "add":
        if (t1 === t2) {
          // 两个小数位数相同
          result = n1 + n2;
        } else if (t1 > t2) {
          // o1 小数位 大于 o2
          result = n1 + n2 * (t1 / t2);
        } else {
          // o1 小数位 小于 o2
          result = n1 * (t2 / t1) + n2;
        }
        return result / max;
      case "subtract":
        if (t1 === t2) {
          result = n1 - n2;
        } else if (t1 > t2) {
          result = n1 - n2 * (t1 / t2);
        } else {
          result = n1 * (t2 / t1) - n2;
        }
        return result / max;
      case "multiply":
        result = (n1 * n2) / (t1 * t2);
        return result;
      case "divide":
        result = (n1 / n2) * (t2 / t1);
        return result;
    }
  }

  // 加减乘除的四个接口
  function add(a, b, digits) {
    return operation(a, b, digits, "add");
  }
  function subtract(a, b, digits) {
    return operation(a, b, digits, "subtract");
  }
  function multiply(a, b, digits) {
    return operation(a, b, digits, "multiply");
  }
  function divide(a, b, digits) {
    return operation(a, b, digits, "divide");
  }

  // exports
  return {
    add: add,
    subtract: subtract,
    multiply: multiply,
    divide: divide,
  };
}

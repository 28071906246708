import {
  UserLayout,
  TabLayout,
  RouteView,
  BlankLayout,
  PageView,
} from "@/components/layouts";

/**
 * 走菜单，走权限控制
 * @type {[null,null]}
 */
export const asyncRouterMap = [
  // {
  //   path: '/',
  //   name: 'dashboard',
  //   component: TabLayout,
  //   meta: { title: '首頁' },
  //   redirect: '/dashboard/analysis',
  //   children: [

  //   ]
  // },
  {
    path: "*",
    redirect: "/404",
    hidden: true,
  },
];

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: "/user",
    component: UserLayout,
    redirect: "/user/login",
    hidden: true,
    children: [
      {
        path: "login",
        name: "login",
        component: () =>
          import(/* webpackChunkName: "user" */ "@/views/user/Login"),
      },
      {
        path: "register",
        name: "register",
        component: () =>
          import(/* webpackChunkName: "user" */ "@/views/user/Register"),
      },
    ],
  },
  {
    path: "/dashboard",
    component: TabLayout,
    redirect: "/dashboard/analysis",
    children: [
      {
        path: "analysis",
        name: "analysis",
        meta: { title: "首頁" },
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ "@/views/dashboard/Analysis"
          ),
      },
    ],
  },
];

import Vue from "vue";
import { login, logout } from "@/api/login";
import {
  ACCESS_TOKEN,
  USER_NAME,
  USER_INFO,
  UI_CACHE_DB_DICT_DATA,
  USER_ID,
  USER_LOGIN_NAME,
} from "@/store/mutation-types";
import { welcome } from "@/utils/util";
import { queryPermissionsByUser } from "@/api/api";
import { getAction } from "@/api/manage";

const user = {
  state: {
    token: "",
    username: "",
    realname: "",
    welcome: "",
    avatar: "",
    permissionList: [],
    info: {},
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_NAME: (state, { username, realname, welcome }) => {
      state.username = username;
      state.realname = realname;
      state.welcome = welcome;
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar;
    },
    SET_PERMISSIONLIST: (state, permissionList) => {
      state.permissionList = permissionList;
    },
    SET_INFO: (state, info) => {
      state.info = info;
    },
  },

  actions: {
    // CAS验证登录
    ValidateLogin({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        getAction("/cas/client/validateLogin", userInfo)
          .then((response) => {
            console.log("----cas 登录--------", response);
            if (response.success) {
              const result = response.result;
              const userInfo = result.userInfo;
              Vue.ls.set(ACCESS_TOKEN, result.token, 7 * 24 * 60 * 60 * 1000);
              Vue.ls.set(USER_NAME, userInfo.username, 7 * 24 * 60 * 60 * 1000);
              Vue.ls.set(USER_INFO, userInfo, 7 * 24 * 60 * 60 * 1000);
              commit("SET_TOKEN", result.token);
              commit("SET_INFO", userInfo);
              commit("SET_NAME", {
                username: userInfo.username,
                realname: userInfo.realname,
                welcome: welcome(),
              });
              commit("SET_AVATAR", userInfo.avatar);
              resolve(response);
            } else {
              resolve(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 登录
    Login({ commit }, userInfo) {
      console.log("----登录--------", userInfo);
      return new Promise((resolve, reject) => {
        login(userInfo)
          .then((response) => {
            console.log(response);
            const token = response.data.token;
            const userInfo = response.data.user;
            let userBtn = [
              { btnStr: "1,3", funId: 272 },
              { btnStr: "1,2", funId: 294 },
              { btnStr: "1", funId: 336 },
              { btnStr: "1", funId: 358 },
              { btnStr: "1,2", funId: 368 },
              { btnStr: "1", funId: 13 },
              { btnStr: "1", funId: 14 },
              { btnStr: "1", funId: 281 },
              { btnStr: "1", funId: 243 },
              { btnStr: "1", funId: 16 },
              { btnStr: "1", funId: 247 },
              { btnStr: "1,3", funId: 267 },
              { btnStr: "1,2", funId: 301 },
              { btnStr: "1", funId: 344 },
              { btnStr: "1,3,5", funId: 355 },
              { btnStr: "1", funId: 248 },
              { btnStr: "1", funId: 250 },
              { btnStr: "1", funId: 255 },
              { btnStr: "1,3", funId: 262 },
              { btnStr: "1", funId: 268 },
              { btnStr: "1,2", funId: 302 },
              { btnStr: "1", funId: 303 },
              { btnStr: "1", funId: 322 },
              { btnStr: "1,3", funId: 327 },
              { btnStr: "1,3,5", funId: 354 },
              { btnStr: "1", funId: 256 },
              { btnStr: "1", funId: 264 },
              { btnStr: "1", funId: 275 },
              { btnStr: "1,3", funId: 280 },
              { btnStr: "1,2", funId: 283 },
              { btnStr: "1,3", funId: 307 },
              { btnStr: "1", funId: 314 },
              { btnStr: "1", funId: 321 },
              { btnStr: "1,3", funId: 332 },
              { btnStr: "1,3", funId: 334 },
              { btnStr: "1,3", funId: 249 },
              { btnStr: "1", funId: 304 },
              { btnStr: "1,3", funId: 306 },
              { btnStr: "1", funId: 320 },
              { btnStr: "1", funId: 369 },
              { btnStr: "1", funId: 251 },
              { btnStr: "1,3", funId: 263 },
              { btnStr: "1,5", funId: 269 },
              { btnStr: "1", funId: 279 },
              { btnStr: "1,2", funId: 291 },
              { btnStr: "1,3", funId: 325 },
              { btnStr: "1", funId: 331 },
              { btnStr: "1", funId: 345 },
              { btnStr: "1,2,5", funId: 361 },
              { btnStr: "1", funId: 366 },
              { btnStr: "1,3", funId: 266 },
              { btnStr: "1", funId: 274 },
              { btnStr: "1,2", funId: 296 },
              { btnStr: "1", funId: 362 },
              { btnStr: "1,2", funId: 253 },
              { btnStr: "1,2", funId: 265 },
              { btnStr: "1", funId: 257 },
              { btnStr: "1,2", funId: 288 },
              { btnStr: "1,2", funId: 298 },
              { btnStr: "1,2", funId: 318 },
              { btnStr: "1,2", funId: 364 },
              { btnStr: "1,3", funId: 326 },
              { btnStr: "1,3", funId: 259 },
              { btnStr: "1", funId: 317 },
              { btnStr: "1", funId: 323 },
              { btnStr: "1", funId: 330 },
              { btnStr: "1", funId: 333 },
              { btnStr: "1", funId: 339 },
              { btnStr: "1", funId: 340 },
              { btnStr: "1,3", funId: 341 },
              { btnStr: "1", funId: 349 },
              { btnStr: "1,3,5", funId: 352 },
              { btnStr: "1,3", funId: 260 },
              { btnStr: "1,3", funId: 329 },
              { btnStr: "1,3", funId: 357 },
              { btnStr: "1,3", funId: 337 },
              { btnStr: "1,3", funId: 308 },
              { btnStr: "1,3", funId: 311 },
              { btnStr: "1,3", funId: 312 },
              { btnStr: "1,3", funId: 347 },
              { btnStr: "1,3", funId: 346 },
              { btnStr: "1", funId: 351 },
              { btnStr: "1", funId: 350 },
              { btnStr: "1", funId: 328 },
            ];
            // const userBtn = response.data.userBtn;
            Vue.ls.set(ACCESS_TOKEN, token, 1 * 24 * 60 * 60 * 1000);
            commit("SET_TOKEN", token);
            Vue.ls.set(USER_ID, userInfo.id, 7 * 24 * 60 * 60 * 1000);
            Vue.ls.set(
              USER_LOGIN_NAME,
              userInfo.loginName,
              7 * 24 * 60 * 60 * 1000
            );
            Vue.ls.set(USER_INFO, userInfo, 7 * 24 * 60 * 60 * 1000);
            Vue.ls.set("winBtnStrList", userBtn, 7 * 24 * 60 * 60 * 1000);
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // 获取用户信息
    GetPermissionList({ commit }) {
      return new Promise((resolve, reject) => {
        // ({ pNumber: 0 }).then((response) => {
        let response = {
          code: 200,
          message: "操作成功",
          data: {
            menu: [
              {
                component: "/layouts/TabLayout",
                icon: "home",
                id: 0,
                text: "首頁",
                url: "/dashboard/analysis",
              },

              {
                component: "/layouts/TabLayout",
                children: [
                  {
                    component: "/orderly/orderlyList",
                    icon: "profile",
                    id: 336,
                    text: "下单历史列表",
                    url: "/orderly/orderlyList",
                  }
                ],
                icon: "database",
                id: 319,
                text: "数据中心管理",
                url: "/orderly",
              },
              
              {
                component: "/layouts/TabLayout",
                children: [
                  {
                    component: "/account/accountList",
                    icon: "profile",
                    id: 294,
                    text: "交易对列表",
                    url: "/account/accountList",
                  },
                ],
                icon: "database",
                id: 351,
                text: "交易对管理",
                url: "/account",
              },
              // {
              //   component: "/layouts/TabLayout",
              //   children: [
              //     {
              //       component: "/organization/StaffList",
              //       icon: "profile",
              //       id: 281,
              //       text: "员工管理",
              //       url: "/system/staff",
              //     },
              //     {
              //       component: "/system/OrganizationList",
              //       icon: "profile",
              //       id: 243,
              //       text: "部门管理",
              //       url: "/system/organization",
              //     },
              //     {
              //       component: "/production/WorkerList",
              //       icon: "profile",
              //       id: 257,
              //       text: "工人管理",
              //       url: "/production/worker",
              //     },
              //     {
              //       component: "/production/DispatchList",
              //       icon: "profile",
              //       id: 318,
              //       text: "派工管理",
              //       url: "/production/dispatchList",
              //     },
              //     {
              //       component: "/production/OvertimeWorkList",
              //       icon: "profile",
              //       id: 317,
              //       text: "加班管理",
              //       url: "/production/overtimeWork",
              //     },
              //     {
              //       component: "/browse/BrowseRecordList",
              //       icon: "profile",
              //       id: 352,
              //       text: "浏览统计",
              //       url: "/browse/record",
              //     },
              //   ],
              //   icon: "database",
              //   id: 350,
              //   text: "OA管理",
              //   url: "/oa",
              // },
              {
                component: "/layouts/TabLayout",
                children: [
                  // {
                  //   component: "/system/RoleList",
                  //   icon: "profile",
                  //   id: 13,
                  //   text: "角色管理",
                  //   url: "/system/role",
                  // },
                  {
                    component: "/system/UserList",
                    icon: "profile",
                    id: 14,
                    text: "用户管理",
                    url: "/system/user",
                  },
                  // {
                  //   component: "/system/LogList",
                  //   icon: "profile",
                  //   id: 15,
                  //   text: "日志管理",
                  //   url: "/system/log",
                  // },
                  // {
                  //   component: "/system/FunctionConfig",
                  //   icon: "profile",
                  //   id: 16,
                  //   text: "功能设置",
                  //   url: "/system/config",
                  // },
                ],
                icon: "setting",
                id: 1,
                text: "系统设置",
                url: "/system",
              },
            ],
          },
        };
        const menuData = response.data.menu;

        commit("SET_PERMISSIONLIST", menuData);

        resolve(response);
        // });
      });
    },

    // 登出
    Logout({ commit, state }) {
      return new Promise((resolve) => {
        //let logoutToken = state.token;
        commit("SET_TOKEN", "");
        Vue.ls.set(ACCESS_TOKEN, "", 1 * 24 * 60 * 60 * 1000);
        commit("SET_PERMISSIONLIST", []);
        Vue.ls.remove(USER_ID);
        Vue.ls.remove(USER_LOGIN_NAME);
        Vue.ls.remove(USER_INFO);
        Vue.ls.remove(UI_CACHE_DB_DICT_DATA);
        logout()
          .then(() => {
            resolve();
          })
          .catch(() => {
            resolve();
          });
      });
    },
    // 第三方登录
    ThirdLogin({ commit }, token) {
      return new Promise((resolve, reject) => {
        thirdLogin(token)
          .then((response) => {
            if (response.code == "200") {
              const result = response.result;
              const userInfo = result.userInfo;
              Vue.ls.set(ACCESS_TOKEN, result.token, 7 * 24 * 60 * 60 * 1000);
              Vue.ls.set(USER_NAME, userInfo.username, 7 * 24 * 60 * 60 * 1000);
              Vue.ls.set(USER_INFO, userInfo, 7 * 24 * 60 * 60 * 1000);
              commit("SET_TOKEN", result.token);
              commit("SET_INFO", userInfo);
              commit("SET_NAME", {
                username: userInfo.username,
                realname: userInfo.realname,
                welcome: welcome(),
              });
              commit("SET_AVATAR", userInfo.avatar);
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};

export default user;

import Vue from "vue";
import router from "./router";
import store from "./store";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
import { notification, Modal } from "ant-design-vue";
import { ACCESS_TOKEN, INDEX_MAIN_PAGE_PATH } from "@/store/mutation-types";
import { generateIndexRouter } from "@/utils/util";

NProgress.configure({ showSpinner: false }); // NProgress Configuration

const whiteList = ["/user/login"]; // no redirect whitelist

router.beforeEach((to, from, next) => {
  if (Vue.ls.get(ACCESS_TOKEN)) {
    // 确定是否有token
    /* 有token */
    // 获取当天凌晨的时间
    if (to.path === "/user/login") {
      next({ path: INDEX_MAIN_PAGE_PATH });
      NProgress.done();
    } else {
      if (store.getters.permissionList.length === 0) {
        store
          .dispatch("GetPermissionList")
          .then((res) => {
            const menuData = res.data.menu;
            if (
              menuData === null ||
              menuData === "" ||
              menuData === undefined
            ) {
              return;
            }
            let constRoutes = [];
            constRoutes = generateIndexRouter(menuData);

            // 添加主界面路由
            store.dispatch("UpdateAppRouter", { constRoutes }).then(() => {
              // 根据roles权限生成可访问的路由表
              // 动态添加可访问路由表
              router.addRoutes(store.getters.addRouters);
              let handleAccessible = false;
              constRoutes.map((item) => {
                if (!item.children && item.path === to.path) {
                  handleAccessible = true;
                  return;
                }
                if (item.children) {
                  item.children.map((iitem) => {
                    if (iitem.path === to.path) {
                      handleAccessible = true;
                      return;
                    }
                  });
                }
              });
              let redirect;
              if (!handleAccessible) {
                redirect = decodeURIComponent("/");
              } else {
                redirect = decodeURIComponent(from.query.redirect || to.path);
              }
              next({ path: redirect });
            });
          })
          .catch(() => {
            store.dispatch("Logout").then(() => {
              next({ path: "/user/login", query: { redirect: to.fullPath } });
            });
          });
      } else {
        next();
      }
    }
  } else {
    /*  没有token*/
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next();
    } else {
      next({ path: "/user/login", query: { redirect: to.fullPath } });
      NProgress.done();
    }
  }
});

import Vue from "vue";
import axios from "axios";
import store from "@/store";
import { VueAxios } from "./axios";
import { Modal, notification } from "ant-design-vue";
import { ACCESS_TOKEN } from "@/store/mutation-types";

/**
 * 【指定 axios的 baseURL】
 * 如果手工指定 baseURL: '/mes_boss'
 * 則映射後端域名，通過 vue.config.js
 * @type {*|string}
 */
let apiBaseUrl = window._CONFIG["domianURL"];
// let apiBaseUrl = '/hengtao-mes';
//console.log("apiBaseUrl= ",apiBaseUrl)
// 創建 axios 實例
const service = axios.create({
  baseURL: apiBaseUrl, // api base_url
  timeout: 600000, // 請求超時時間
});

const err = (error) => {
  if (error.response) {
    let data = error.response.data;
    const token = Vue.ls.get(ACCESS_TOKEN);
    console.log("------異常響應------", token);
    console.log("------異常響應------", error.response.status);
    switch (error.response.status) {
      case 403:
        notification.error({
          message: "系統提示",
          description: "拒絕訪問",
          duration: 4,
        });
        break;
      case 500:
        //notification.error({ message: '系統提示', description:'Token失效，請重新登錄!',duration: 4})
        if (token && data.message == "Token失效，請重新登錄") {
          // update-begin- --- author:scott ------ date:20190225 ---- for:Token失效采用彈框模式，不直接跳轉----
          store.dispatch("Logout").then(() => {
            window.location.reload();
          });
          Modal.error({
            title: "登錄已過期",
            content: "很抱歉，登錄已過期，請重新登錄",
            okText: "重新登錄",
            mask: false,
            onOk: () => {
              store.dispatch("Logout").then(() => {
                Vue.ls.remove(ACCESS_TOKEN);
                window.location.reload();
              });
            },
          });
          // update-end- --- author:scott ------ date:20190225 ---- for:Token失效采用彈框模式，不直接跳轉----
        }
        break;
      case 404:
        notification.error({
          message: "系統提示",
          description: "很抱歉，資源未找到!",
          duration: 4,
        });
        break;
      case 504:
        notification.error({ message: "系統提示", description: "網絡超時" });
        break;
      case 401:
        notification.error({
          message: "系統提示",
          description: "未授權，請重新登錄",
          duration: 4,
        });
        if (token) {
          store.dispatch("Logout").then(() => {
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          });
        }
        break;
      default:
        notification.error({
          message: "系統提示",
          description: data.message,
          duration: 4,
        });
        break;
    }
  }
  return Promise.reject(error);
};

const reponse = (reponse) => {
  let data = reponse.data;

  if (data.code) {
    if (data.code != 200) {
      if (data.data) {
        notification.error({ message: data.data, description: "系統提示" });
      } else if (data.message) {
        notification.error({ message: data.message, description: "系統提示" });
      }
      return Promise.reject(reponse);
    } else {
      return data;
    }
  }

  return data;
};

// request interceptor
service.interceptors.request.use(
  (config) => {
    const token = Vue.ls.get(ACCESS_TOKEN);
    if (token) {
      config.headers["X-Access-Token"] = token; // 讓每個請求攜帶自定義 token 請根據實際情況自行修改
    }
    if (config.method == "get") {
      config.params = {
        _t: Date.parse(new Date()) / 1000,
        ...config.params,
      };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(reponse, err);

const installer = {
  vm: {},
  install(Vue, router = {}) {
    Vue.use(VueAxios, router, service);
  },
};

export { installer as VueAxios, service as axios };

<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view />
    </div>
  </a-config-provider>
</template>
<script>
import zhTW from "ant-design-vue/lib/locale-provider/zh_TW";
// import enquireScreen from "@/utils/device";

export default {
  data() {
    return {
      locale: zhTW,
    };
  },
  created() {
    let that = this;
    // enquireScreen((deviceType) => {
    //   // tablet
    //   if (deviceType === 0) {
    //     that.$store.commit("TOGGLE_DEVICE", "mobile");
    //     that.$store.dispatch("setSidebar", false);
    //   }
    //   // mobile
    //   else if (deviceType === 1) {
    //     that.$store.commit("TOGGLE_DEVICE", "mobile");
    //     that.$store.dispatch("setSidebar", false);
    //   } else {
    //     that.$store.commit("TOGGLE_DEVICE", "desktop");
    //     that.$store.dispatch("setSidebar", true);
    //   }
    // });
  },
};
</script>
<style>
#app {
  height: 100%;
}
</style>

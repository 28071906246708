<template>
  <div class="user-wrapper" :class="theme">
    <a-dropdown>
      <span class="action ant-dropdown-link user-dropdown-menu">
        <span>歡迎您，{{ nickname() }}</span>
      </span>
      <a-menu slot="overlay" class="user-dropdown-menu-wrapper">
        <a-menu-item key="4" @click="updatePassword">
          <a-icon type="setting" />
          <span>密碼修改</span>
        </a-menu-item>
      </a-menu>
    </a-dropdown>
    <span class="action">
      <a class="logout_title" href="javascript:;" @click="handleLogout">
        <a-icon type="logout" />
        <span>&nbsp;退出登錄</span>
      </a>
    </span>
    <user-password ref="userPassword"></user-password>
  </div>
</template>

<script>
import Vue from "vue";
import UserPassword from "./UserPassword";
import { mapActions, mapGetters, mapState } from "vuex";
import { mixinDevice } from "@/utils/mixin.js";
import { ACCESS_TOKEN, LOGIN_MSG, USER_INFO } from "@/store/mutation-types";

export default {
  name: "UserMenu",
  mixins: [mixinDevice],
  data() {
    return {
      // update-begin author:sunjianlei date:20200219 for: 头部菜单搜索规范命名 --------------
      searchMenuOptions: [],
      searchMenuComp: "span",
      searchMenuVisible: false,
      systemUrl: window.SYS_URL,
      showAd: false,
      // update-begin author:sunjianlei date:20200219 for: 头部菜单搜索规范命名 --------------
    };
  },
  components: {
    UserPassword,
  },
  props: {
    theme: {
      type: String,
      required: false,
      default: "dark",
    },
  },
  /* update_begin author:zhaoxin date:20191129 for: 做头部菜单栏导航*/
  created() {
    let lists = [];
    this.searchMenus(lists, this.permissionMenuList);
    this.searchMenuOptions = [...lists];
  },
  computed: {
    ...mapState({
      // 后台菜单
      permissionMenuList: (state) => state.user.permissionList,
    }),
  },
  /* update_end author:zhaoxin date:20191129 for: 做头部菜单栏导航*/
  watch: {
    // update-begin author:sunjianlei date:20200219 for: 菜单搜索改为动态组件，在手机端呈现出弹出框
    device: {
      immediate: true,
      handler() {
        this.searchMenuVisible = false;
        this.searchMenuComp = this.isMobile() ? "a-modal" : "span";
      },
    },
    // update-end author:sunjianlei date:20200219 for: 菜单搜索改为动态组件，在手机端呈现出弹出框
  },
  methods: {
    /* update_begin author:zhaoxin date:20191129 for: 做头部菜单栏导航*/
    showClick() {
      this.searchMenuVisible = true;
    },
    hiddenClick() {
      this.shows = false;
    },
    /* update_end author:zhaoxin date:20191129 for: 做头部菜单栏导航*/
    ...mapActions(["Logout"]),
    ...mapGetters(["nickname", "loginName", "userInfo"]),
    handleLogout() {
      const that = this;

      this.$confirm({
        title: "提示",
        content: "真的要注销登录吗 ?",
        onOk() {
          return that
            .Logout({})
            .then(() => {
              window.location.href = "/";
              //window.location.reload()
              Vue.ls.remove(USER_INFO);
              Vue.ls.remove(LOGIN_MSG);
              Vue.ls.remove("winBtnStrList");
              Vue.ls.remove("roleType");
              Vue.ls.remove(ACCESS_TOKEN);
            })
            .catch((err) => {
              that.$message.error({
                title: "错误",
                description: err.message,
              });
            });
        },
        onCancel() {},
      });
    },
    updatePassword() {
      let userId = this.userInfo().id;
      this.$refs.userPassword.show(userId);
    },
    /* update_begin author:zhaoxin date:20191129 for: 做头部菜单栏导航*/
    searchMenus(arr, menus) {
      for (let i of menus) {
        if (!i.hidden && "layouts/RouteView" !== i.component) {
          arr.push(i);
        }
        if (i.children && i.children.length > 0) {
          this.searchMenus(arr, i.children);
        }
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // update_begin author:sunjianlei date:20191230 for: 解决外部链接打开失败的问题
    searchMethods(value) {
      let route = this.searchMenuOptions.filter((item) => item.id === value)[0];
      if (
        route.meta.internalOrExternal === true ||
        route.component.includes("layouts/IframePageView")
      ) {
        window.open(route.meta.url, "_blank");
      } else {
        this.$router.push({ path: route.path });
      }
      this.searchMenuVisible = false;
    },
    // update_end author:sunjianlei date:20191230 for: 解决外部链接打开失败的问题
    /*update_end author:zhaoxin date:20191129 for: 做头部菜单栏导航*/
  },
};
</script>

<style lang="less" scoped>
/* update_begin author:zhaoxin date:20191129 for: 让搜索框颜色能随主题颜色变换*/
/* update-begin author:sunjianlei date:20191220 for: 解决全局样式冲突问题 */
.user-wrapper .search-input {
  width: 180px;
  color: inherit;

  /deep/ .ant-select-selection {
    background-color: inherit;
    border: 0;
    border-bottom: 1px solid white;
    &__placeholder,
    &__field__placeholder {
      color: inherit;
    }
  }
}
/* update-end author:sunjianlei date:20191220 for: 解决全局样式冲突问题 */
/* update_end author:zhaoxin date:20191129 for: 让搜索框颜色能随主题颜色变换*/
</style>

<style scoped>
.cloud_title {
  color: inherit;
  font-weight: bold;
  text-decoration: none;
}
.logout_title {
  color: inherit;
  text-decoration: none;
}
</style>
